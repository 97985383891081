// import { FormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AddressService, ZipRecord, CityRecord, StreetRecord, HouseRecord, FlatRecord } from './../address.service';

interface zips {
  name: string;
  zipRecord: ZipRecord;
}

interface cities {
  name: string;
  cityRecord: CityRecord;
}

interface streets {
  name: string;
  streetRecord: StreetRecord;
}

interface houses {
  name: string;
  houseRecord: HouseRecord;
}

interface flats {
  name: string;
  flatRecord: FlatRecord;
}

@Component({
  selector: 'select-existing',
  templateUrl: './select-existing.component.html',
  styleUrls: ['./select-existing.component.css']
})
export class SelectExistingComponent implements OnInit {

  allzips: Array<zips> = [];
  allcities: Array<cities> = [];
  // allcities: Array<cities> = [{name: "Nekeresd", cityRecord: null}];
  // allcities: Array<cities> = [{name: "Nekeresd", cityRecord: null}, {name: "Kikerics", cityRecord: null}];
  allstreets: Array<streets> = [];
  allhouses: Array<houses> = [];
  allflats: Array<flats> = [];

  // isZipOpen: boolean = false;
  // isCityOpen: boolean = false;

  
  private selectedZipValue: string = "";
  private selectedCityValue: number = 0;
  private selectedStreetValue: number = 0;
  private selectedHouseValue: number = 0;
  private selectedFlatValue: number = 0;

  private actualZip: string = "";
  private actualCity: string = "";
  private actualStreet: string = "";
  private actualHouse: string = "";
  private actualFlat: string = "";
  
  private isLoadPending: boolean = false;
  private isZipLoadPending: boolean = false;
  private isCityLoadPending: boolean = false;
  private isStreetLoadPending: boolean = false;
  private isHouseLoadPending: boolean = false;
  private isFlatLoadPending: boolean = false;
  private fullNeeded: boolean = false;
  private isAddressSelected: boolean = false;
  private doServe: boolean = false;

  private addressService : AddressService;

  constructor() {}

  onZipSelected(selectedZip) {
    if (selectedZip.itemSelected.name != this.actualZip) {
      this.actualZip = selectedZip.itemSelected.name;
      this.isAddressSelected = false;
      let zip = selectedZip.itemSelected.zipRecord.zip;
      let _allcities: Array<cities> = [];
      this.selectedZipValue = zip;
      this.isLoadPending = true;
      this.isCityLoadPending = true;

      this.allstreets = [];
      this.allhouses = [];
      this.allflats = [];
      this.fullNeeded = false;
      this.actualCity = "";
      this.actualStreet = "";
      this.actualHouse = "";

      this.addressService.getCitiesbyZip(zip).then( response => {
        for(let i=0; i<response.length; i++) {
          _allcities[i] = {name: response[i].city, cityRecord: response[i]};
        }
        this.isLoadPending = false;
        this.isCityLoadPending = false;
        this.allcities = _allcities;
      });
    };
  }

  onCitySelected(selectedCity) {
    if (selectedCity.itemSelected.name != this.actualCity) {
      this.actualCity = selectedCity.itemSelected.name;
      this.isAddressSelected = false;
      let cityid = selectedCity.itemSelected.cityRecord.postcodeCityID;
      let _allstreets: Array<streets> = [];
      this.selectedCityValue = selectedCity.itemSelected.cityRecord.postcodeCityID;
      this.isLoadPending = true;
      this.isStreetLoadPending = true;

      this.allhouses = [];
      this.allflats = [];
      this.fullNeeded = false;
      this.actualStreet = "";
      this.actualHouse = "";

      this.addressService.getStreetsbyCity(cityid).then( response => {
        for(let i=0; i<response.length; i++) {
          _allstreets[i] = {name: response[i].streetFull, streetRecord: response[i]};
        }
        this.isLoadPending = false;
        this.isStreetLoadPending = false;
        this.allstreets = _allstreets;
      });
    };
  }

  onStreetSelected(selectedStreet) {
    if (selectedStreet.itemSelected.name != this.actualStreet) {
      this.actualStreet = selectedStreet.itemSelected.name;
      this.isAddressSelected = false;
      let streetID = selectedStreet.itemSelected.streetRecord.streetID;
      this.isLoadPending = true;
      this.isHouseLoadPending = true;

      this.allflats = [];
      this.fullNeeded = false;
      this.actualHouse = "";

      let _allhouses: Array<houses> = [];
      this.selectedStreetValue = selectedStreet.itemSelected.streetRecord.streetID;
      this.addressService.getHousesbyStreet(streetID).then( response => {
        for(let i=0; i<response.length; i++) {
          _allhouses[i] = {name: response[i].housenumber, houseRecord: response[i]};
        }
        this.isLoadPending = false;
        this.isHouseLoadPending = false;
        this.allhouses = _allhouses;
      });
    };
  }

  onHouseNumberSelected(selectedHouse) {
    if (selectedHouse.itemSelected.name != this.actualHouse) {
      this.actualHouse = selectedHouse.itemSelected.name;
      let siteID = selectedHouse.itemSelected.houseRecord.siteid;
      this.isLoadPending = true;
      this.isFlatLoadPending = true;
      this.fullNeeded = !selectedHouse.itemSelected.houseRecord.isfull;
      let _allflats: Array<flats> = [];
      this.selectedHouseValue = selectedHouse.itemSelected.houseRecord.siteid;
      this.addressService.getFlatsbyHouse(siteID).then( response => {
        for(let i=0; i<response.length; i++) {
          _allflats[i] = {name: response[i].doorInfo, flatRecord: response[i]};
        }
        this.isLoadPending = false;
        this.isFlatLoadPending = false;
        this.allflats = _allflats;
        this.isAddressSelected = !this.fullNeeded;
        if (!this.fullNeeded && selectedHouse.itemSelected.houseRecord.doServe)
          this.doServe = true;;
      });
    };
  }

  onFlatSelected(selectedFlat) {
    this.isAddressSelected = true;
    this.doServe = selectedFlat.itemSelected.flatRecord.doServe;
  }

  ngOnInit() {
    let _allzips: Array<zips> = [];
    this.addressService = new AddressService;
    this.isLoadPending = true;
    this.isZipLoadPending = true;
    this.addressService.getZips().then( response => {
      for(let i=0; i<response.length; i++) {
        _allzips[i] = {name: response[i].zip, zipRecord: response[i]};
      }
      this.isLoadPending = false;
      this.isZipLoadPending = false;
      this.allzips = _allzips;
    });



  }

}
;