import { FormGroup, FormControl} from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// +++ Kell ez?
export interface FilteredDropdownComponentArguments {
  selectedItem: any;
}

@Component({
  selector: 'tna-filtered-dropdown',
  templateUrl: './filtered-dropdown.component.html',
  styleUrls: ['./filtered-dropdown.component.css']
})
export class FilteredDropdownComponent implements OnInit {
@Input('allowopen') allowopen: boolean = false;
@Input('placing') placing: string = 'alone';
@Input("zindex") zindex: number = 2000;
@Input("loading") isLoading: boolean = false;
@Input("filtertype") filterType: string = "starts"; //"includes"
@Input('listtofilter')
  set allitems(allitems:Array<any>){
    this._allitems = allitems;
    this.actualitems = this._allitems;
    if (Array.isArray(allitems) 
        && this.allowopen
        )
    {
      if(allitems.length == 1)
      {
        this.itemSelected(allitems[0]);
      }
      else {
        this.selectedItemValue = '';
        this.isSearchOpen = false;
        if (allitems.length>1)
        {
          this.isSearchOpen = true;
        };
      };       
    }
  }; 

private _allitems: Array<any>;
private actualitems: Array<any>;;

@Output('selected') selected = new EventEmitter();

private selectedItemValue: string = '';
private searchFilter: string;
private isSearchOpen: boolean = false;
private listIndex: number = 0;

form =new FormGroup ({
  selectField: new FormControl(),
  searchFilter: new FormControl()
});
    
  // get selectField() {
  //   return this.form.get('selectField');
  // }

  // get searchFilter() : AbstractControl {
  //   return this.form.get('searchFilter');
  // }

  itemSelected(item) {
    this.selectedItemValue = item.name;
    this.isSearchOpen = false;
    this.selected.emit({itemSelected: item});
  }

  itemFilter(value: string, $event) {
    let keysEnum = { tab: 9, esc: 27, left: 37, up: 38, right: 39, down: 40, enter: 13 };
    let keysToCheck = [
        keysEnum.left,
        keysEnum.right,
        keysEnum.up,
        keysEnum.down,
        keysEnum.enter,
        keysEnum.tab, // tab left here to avoid listIndex rest in case of tab field left
        keysEnum.esc];
    let _element: HTMLElement = null;

    if (keysToCheck.indexOf($event.keyCode) >= 0 ) {
      $event.stopPropagation();

      switch ($event.keyCode) {
        case keysEnum.up:
          this.listIndex--;
          if (this.listIndex < 0) this.listIndex = 0;
          _element = document.getElementById("item"+this.listIndex);
          _element.scrollIntoView(true);
          break;
        case keysEnum.down:
          this.listIndex++;
          if (this.listIndex > this._allitems.length-1) this.listIndex = this._allitems.length-1;
          _element = document.getElementById("item"+this.listIndex);
          _element.scrollIntoView(false);
          break;
        case keysEnum.enter:
          this.itemSelected(this.actualitems[this.listIndex]);
          break;
        // tab is closed out from the keys, while the next field loses autofocus...
        // case keysEnum.tab:
        //   this.itemSelected(this.actualitems[this.listIndex]);
        //   break;
        case keysEnum.esc:
          this.toggleSelect();
          break;
      }
    }
    else {
      this.actualitems=[];
      this.listIndex = 0;
      value = value.toLowerCase();
      let currentItem: any;
      for (let i=0; i<this._allitems.length; i++) {
        currentItem = this._allitems[i];
        if(this.filterType === "starts") {
          if(currentItem.name.toLowerCase().startsWith(value))
            this.actualitems.push(currentItem);
        }   
        else {
          if(currentItem.name.toLowerCase().includes(value))
            this.actualitems.push(currentItem);
        };    
      };

      if (this.actualitems.length == 1) {
        this.itemSelected(this.actualitems[0]);
      };
    };
  }

  toggleSelect() {
    this.isSearchOpen = ! this.isSearchOpen;
    if (this.isSearchOpen)
      this.actualitems = this._allitems;
    this.listIndex = 0;
  }

  ngOnInit() {
  }

}
