import { Injectable } from '@angular/core';

// REDIS-hez kapcsolódáshoz megjegyzések
// * a zip az postcode
// * a postcodeCityID az postcodeCityID
// * meg kell oldani, hogy a REDIS JSON-ban a kulcsokról lekerüljön a "


export interface ZipRecord {
    zip: string;
}

export interface CityRecord {
    zip: string;
    city: string;
    postcodeCityID: number;
}

export interface StreetRecord {
    postcodeCityID: number;
    streetName: string;
    streetType: string;
    streetID: number;
    streetFull: string;
}

export interface HouseRecord {
    streetid: number;
    housenumber: string;
    siteid: number;
    isfull: boolean;
    doServe: boolean;
}

export interface FlatRecord {
    siteid: number;
    doorInfo: string;
    doServe: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AddressService {
delaySimulation: number = 1;


zips: Array<ZipRecord> = [
    {zip: "1114"},
    {zip: "2060"},
    {zip: "2066"},
    {zip: "3554"},
    {zip: "5111"},
    {zip: "8000"},
    {zip: "8085"},
    {zip: "9100"},
    {zip: "9624"}
];

cities: Array<CityRecord> = [
    {zip: "1114", city:"Budapest", postcodeCityID: 11140},
    {zip: "2060", city:"Bicske", postcodeCityID: 1315},
    {zip: "3554", city:"Bükkaranyos", postcodeCityID: 35540},
    {zip: "8085", city:"Vértesboglár", postcodeCityID: 4444},
    {zip: "2066", city:"Szár", postcodeCityID: 3906},
    {zip: "2066", city:"Újbarok", postcodeCityID: 4304},
    {zip: "5111", city:"Jászfelsőszentgyörgy", postcodeCityID: 51100},
    {zip: "9624", city:"Chernelházadamonya", postcodeCityID: 96240},
    {zip: "9100", city:"Tét", postcodeCityID: 91000},
    {zip: "8000", city:"Székesfehérvár", postcodeCityID:3954}
];

streets: Array<StreetRecord> = [
    {postcodeCityID: 11140, streetName: "Kicsi", streetType:"utca",streetID: 11140001,streetFull:"Kicsi utca"},
    {postcodeCityID: 11140, streetName: "Nagy", streetType:"utca",streetID: 11140002,streetFull:"Nagy utca"},
    {postcodeCityID:1315,streetName:"Akácfa",streetType:"utca",streetID:90827,streetFull:"Akácfa utca"},
    {postcodeCityID:1315,streetName:"Apponyi Albert",streetType:"utca",streetID:90828,streetFull:"Apponyi Albert utca"},
    {postcodeCityID:1315,streetName:"Aradi Vértanúk",streetType:"utca",streetID:90829,streetFull:"Aradi Vértanúk utca"},
    {postcodeCityID:1315,streetName:"Arany János",streetType:"utca",streetID:90830,streetFull:"Arany János utca"},
    {postcodeCityID:1315,streetName:"Árpád",streetType:"utca",streetID:90831,streetFull:"Árpád utca"},
    {postcodeCityID:1315,streetName:"Baboshegy",streetType:"",streetID:108791,streetFull:"Baboshegy "},
    {postcodeCityID:1315,streetName:"Bajcsy-zsilinszky",streetType:"utca",streetID:90832,streetFull:"Bajcsy-zsilinszky utca"},
    {postcodeCityID:1315,streetName:"Bányász",streetType:"utca",streetID:90833,streetFull:"Bányász utca"},
    {postcodeCityID:1315,streetName:"Barki",streetType:"utca",streetID:90834,streetFull:"Barki utca"},
    {postcodeCityID:1315,streetName:"Barsi József",streetType:"utca",streetID:90835,streetFull:"Barsi József utca"},
    {postcodeCityID:1315,streetName:"Bartók Béla",streetType:"utca",streetID:90836,streetFull:"Bartók Béla utca"},
    {postcodeCityID:1315,streetName:"Batthyány Lajos",streetType:"utca",streetID:90837,streetFull:"Batthyány Lajos utca"},
    {postcodeCityID:1315,streetName:"Béke",streetType:"utca",streetID:90838,streetFull:"Béke utca"},
    {postcodeCityID:1315,streetName:"Bem",streetType:"utca",streetID:90839,streetFull:"Bem utca"},
    {postcodeCityID:1315,streetName:"Bethlen Gábor",streetType:"utca",streetID:90840,streetFull:"Bethlen Gábor utca"},
    {postcodeCityID:1315,streetName:"Bihari",streetType:"utca",streetID:90841,streetFull:"Bihari utca"},
    {postcodeCityID:1315,streetName:"Bocskay",streetType:"köz",streetID:90843,streetFull:"Bocskay köz"},
    {postcodeCityID:1315,streetName:"Bocskay",streetType:"utca",streetID:90842,streetFull:"Bocskay utca"},
    {postcodeCityID:1315,streetName:"Bogya Károly",streetType:"utca",streetID:90844,streetFull:"Bogya Károly utca"},
    {postcodeCityID:1315,streetName:"Botond",streetType:"tér",streetID:90845,streetFull:"Botond tér"},
    {postcodeCityID:1315,streetName:"Búzakalász",streetType:"utca",streetID:90846,streetFull:"Búzakalász utca"},
    {postcodeCityID:1315,streetName:"Csabdi",streetType:"utca",streetID:90847,streetFull:"Csabdi utca"},
    {postcodeCityID:1315,streetName:"Csákvári",streetType:"út",streetID:90848,streetFull:"Csákvári út"},
    {postcodeCityID:1315,streetName:"Csók",streetType:"utca",streetID:90849,streetFull:"Csók utca"},
    {postcodeCityID:1315,streetName:"Csokonai",streetType:"utca",streetID:90850,streetFull:"Csokonai utca"},
    {postcodeCityID:1315,streetName:"Csordakút",streetType:"puszta",streetID:105917,streetFull:"Csordakút puszta"},
    {postcodeCityID:1315,streetName:"Dankó Pista",streetType:"utca",streetID:90851,streetFull:"Dankó Pista utca"},
    {postcodeCityID:1315,streetName:"Deák Ferenc",streetType:"utca",streetID:90852,streetFull:"Deák Ferenc utca"},
    {postcodeCityID:1315,streetName:"Diófa",streetType:"utca",streetID:90853,streetFull:"Diófa utca"},
    {postcodeCityID:1315,streetName:"Dobó",streetType:"utca",streetID:90854,streetFull:"Dobó utca"},
    {postcodeCityID:1315,streetName:"Dózsa György",streetType:"utca",streetID:90855,streetFull:"Dózsa György utca"},
    {postcodeCityID:1315,streetName:"Előhegy",streetType:"",streetID:108871,streetFull:"Előhegy "},
    {postcodeCityID:1315,streetName:"Endresz György",streetType:"utca",streetID:90856,streetFull:"Endresz György utca"},
    {postcodeCityID:1315,streetName:"Erdőalja",streetType:"utca",streetID:90857,streetFull:"Erdőalja utca"},
    {postcodeCityID:1315,streetName:"Erkel Ferenc",streetType:"utca",streetID:90858,streetFull:"Erkel Ferenc utca"},
    {postcodeCityID:1315,streetName:"Erőművi",streetType:"lakótelep",streetID:128420,streetFull:"Erőművi lakótelep"},
    {postcodeCityID:1315,streetName:"Fáy András",streetType:"utca",streetID:90859,streetFull:"Fáy András utca"},
    {postcodeCityID:1315,streetName:"Flórianna",streetType:"tér",streetID:90860,streetFull:"Flórianna tér"},
    {postcodeCityID:1315,streetName:"Forgách Antal",streetType:"utca",streetID:90861,streetFull:"Forgách Antal utca"},
    {postcodeCityID:1315,streetName:"Galagonyás",streetType:"dűlő",streetID:90862,streetFull:"Galagonyás dűlő"},
    {postcodeCityID:1315,streetName:"Gárdonyi",streetType:"utca",streetID:90863,streetFull:"Gárdonyi utca"},
    {postcodeCityID:1315,streetName:"Hársfa",streetType:"utca",streetID:90864,streetFull:"Hársfa utca"},
    {postcodeCityID:1315,streetName:"Hősök",streetType:"tere",streetID:90865,streetFull:"Hősök tere"},
    {postcodeCityID:1315,streetName:"Hunyadi",streetType:"utca",streetID:90866,streetFull:"Hunyadi utca"},
    {postcodeCityID:1315,streetName:"Jakab",streetType:"utca",streetID:90867,streetFull:"Jakab utca"},
    {postcodeCityID:1315,streetName:"Jegenyefa",streetType:"utca",streetID:90868,streetFull:"Jegenyefa utca"},
    {postcodeCityID:1315,streetName:"Jókai",streetType:"utca",streetID:90869,streetFull:"Jókai utca"},
    {postcodeCityID:1315,streetName:"József Attila",streetType:"utca",streetID:90870,streetFull:"József Attila utca"},
    {postcodeCityID:1315,streetName:"Kanizsai",streetType:"utca",streetID:90871,streetFull:"Kanizsai utca"},
    {postcodeCityID:1315,streetName:"Kerecsendi Kiss Márton",streetType:"utca",streetID:90872,streetFull:"Kerecsendi Kiss Márton utca"},
    {postcodeCityID:1315,streetName:"Kinizsi",streetType:"utca",streetID:90873,streetFull:"Kinizsi utca"},
    {postcodeCityID:1315,streetName:"Kisfaludy",streetType:"utca",streetID:90874,streetFull:"Kisfaludy utca"}, //786432
    {postcodeCityID:1315,streetName:"Klapka György",streetType:"utca",streetID:90875,streetFull:"Klapka György utca"},
    {postcodeCityID:1315,streetName:"Kodály Zoltán",streetType:"utca",streetID:90876,streetFull:"Kodály Zoltán utca"},
    {postcodeCityID:1315,streetName:"Kölcsey",streetType:"utca",streetID:90879,streetFull:"Kölcsey utca"},
    {postcodeCityID:1315,streetName:"Kőrösi Csoma",streetType:"utca",streetID:90880,streetFull:"Kőrösi Csoma utca"},
    {postcodeCityID:1315,streetName:"Kossuth",streetType:"tér",streetID:90878,streetFull:"Kossuth tér"},
    {postcodeCityID:1315,streetName:"Kossuth",streetType:"utca",streetID:90877,streetFull:"Kossuth utca"},
    {postcodeCityID:1315,streetName:"Középhegy",streetType:"dűlő",streetID:128421,streetFull:"Középhegy dűlő"},
    {postcodeCityID:1315,streetName:"Lehel",streetType:"köz",streetID:90881,streetFull:"Lehel köz"},
    {postcodeCityID:1315,streetName:"Losonczi",streetType:"utca",streetID:90882,streetFull:"Losonczi utca"},
    {postcodeCityID:1315,streetName:"Madách Imre",streetType:"utca",streetID:90883,streetFull:"Madách Imre utca"},
    {postcodeCityID:1315,streetName:"Magyar Sándor",streetType:"utca",streetID:90884,streetFull:"Magyar Sándor utca"},
    {postcodeCityID:1315,streetName:"Május 1.",streetType:"utca",streetID:90885,streetFull:"Május 1. utca"},
    {postcodeCityID:1315,streetName:"Máléhegy",streetType:"",streetID:131154,streetFull:"Máléhegy "},
    {postcodeCityID:1315,streetName:"Móricz Zsigmond",streetType:"utca",streetID:90886,streetFull:"Móricz Zsigmond utca"},
    {postcodeCityID:1315,streetName:"Munkácsy Mihály",streetType:"utca",streetID:90887,streetFull:"Munkácsy Mihály utca"},
    {postcodeCityID:1315,streetName:"Nagy Károly",streetType:"tér",streetID:90889,streetFull:"Nagy Károly tér"},
    {postcodeCityID:1315,streetName:"Nagy Károly",streetType:"utca",streetID:90888,streetFull:"Nagy Károly utca"},
    {postcodeCityID:1315,streetName:"Nefelejcs",streetType:"utca",streetID:90890,streetFull:"Nefelejcs utca"},
    {postcodeCityID:1315,streetName:"Olajtelep",streetType:"utca",streetID:106326,streetFull:"Olajtelep utca"},
    {postcodeCityID:1315,streetName:"Petőfi",streetType:"tér",streetID:90891,streetFull:"Petőfi tér"},
    {postcodeCityID:1315,streetName:"Petőfi Sándor",streetType:"utca",streetID:90892,streetFull:"Petőfi Sándor utca"},
    {postcodeCityID:1315,streetName:"Prohászka Ottokár",streetType:"utca",streetID:90893,streetFull:"Prohászka Ottokár utca"},
    {postcodeCityID:1315,streetName:"Rákóczi",streetType:"utca",streetID:90894,streetFull:"Rákóczi utca"},
    {postcodeCityID:1315,streetName:"Ravasz László",streetType:"utca",streetID:90895,streetFull:"Ravasz László utca"},
    {postcodeCityID:1315,streetName:"Rigó",streetType:"utca",streetID:90896,streetFull:"Rigó utca"},
    {postcodeCityID:1315,streetName:"Rozgonyi",streetType:"utca",streetID:90897,streetFull:"Rozgonyi utca"},
    {postcodeCityID:1315,streetName:"Rózsa",streetType:"utca",streetID:90898,streetFull:"Rózsa utca"},
    {postcodeCityID:1315,streetName:"Spar",streetType:"út",streetID:117094,streetFull:"Spar út"},
    {postcodeCityID:1315,streetName:"Szarka János",streetType:"tér",streetID:90899,streetFull:"Szarka János tér"},
    {postcodeCityID:1315,streetName:"Széchenyi",streetType:"utca",streetID:90900,streetFull:"Széchenyi utca"},
    {postcodeCityID:1315,streetName:"Szent Imre",streetType:"utca",streetID:90901,streetFull:"Szent Imre utca"},
    {postcodeCityID:1315,streetName:"Szent István",streetType:"út",streetID:90902,streetFull:"Szent István út"},
    {postcodeCityID:1315,streetName:"Szent László",streetType:"utca",streetID:90903,streetFull:"Szent László utca"},
    {postcodeCityID:1315,streetName:"Szondy",streetType:"köz",streetID:90905,streetFull:"Szondy köz"},
    {postcodeCityID:1315,streetName:"Szondy",streetType:"utca",streetID:90904,streetFull:"Szondy utca"},
    {postcodeCityID:1315,streetName:"Táncsics Mihály",streetType:"utca",streetID:90906,streetFull:"Táncsics Mihály utca"},
    {postcodeCityID:1315,streetName:"Tatai",streetType:"út",streetID:90907,streetFull:"Tatai út"},
    {postcodeCityID:1315,streetName:"Téglagyár",streetType:"utca",streetID:90908,streetFull:"Téglagyár utca"},
    {postcodeCityID:1315,streetName:"Thököly",streetType:"utca",streetID:90909,streetFull:"Thököly utca"},
    {postcodeCityID:1315,streetName:"Tölgyfa",streetType:"utca",streetID:90912,streetFull:"Tölgyfa utca"},
    {postcodeCityID:1315,streetName:"Tompa Mihály",streetType:"utca",streetID:90910,streetFull:"Tompa Mihály utca"},
    {postcodeCityID:1315,streetName:"Török Bálint",streetType:"utca",streetID:90913,streetFull:"Török Bálint utca"},
    {postcodeCityID:1315,streetName:"Törökverő",streetType:"utca",streetID:90914,streetFull:"Törökverő utca"},
    {postcodeCityID:1315,streetName:"Tószeg",streetType:"utca",streetID:90911,streetFull:"Tószeg utca"},
    {postcodeCityID:1315,streetName:"Űrhajós",streetType:"utca",streetID:90915,streetFull:"Űrhajós utca"},
    {postcodeCityID:1315,streetName:"Váci Mihály",streetType:"utca",streetID:90916,streetFull:"Váci Mihály utca"},
    {postcodeCityID:1315,streetName:"Vajda János",streetType:"utca",streetID:90917,streetFull:"Vajda János utca"},
    {postcodeCityID:1315,streetName:"Vak Bottyán",streetType:"tér",streetID:118296,streetFull:"Vak Bottyán tér"},
    {postcodeCityID:1315,streetName:"Váradi Dániel",streetType:"utca",streetID:90918,streetFull:"Váradi Dániel utca"},
    {postcodeCityID:1315,streetName:"Varga József",streetType:"utca",streetID:90919,streetFull:"Varga József utca"},
    {postcodeCityID:1315,streetName:"Vasvári Pál",streetType:"utca",streetID:90920,streetFull:"Vasvári Pál utca"},
    {postcodeCityID:1315,streetName:"Vereczkei",streetType:"utca",streetID:90921,streetFull:"Vereczkei utca"},
    {postcodeCityID:1315,streetName:"Virág",streetType:"köz",streetID:90922,streetFull:"Virág köz"},
    {postcodeCityID:1315,streetName:"Vörösmarty",streetType:"utca",streetID:90923,streetFull:"Vörösmarty utca"},
    {postcodeCityID:1315,streetName:"Zrínyi",streetType:"utca",streetID:90924,streetFull:"Zrínyi utca"},
    {postcodeCityID:1315,streetName:"Ady Endre",streetType:"utca",streetID:90826,streetFull:"Ady Endre utca"}

];

houses: Array<HouseRecord> = [
    {streetid: 11140001, housenumber: "1", siteid: 111400010001, isfull: true, doServe: true},
    {streetid: 11140001, housenumber: "2", siteid: 111400010002, isfull: true, doServe: true},
    {streetid: 11140001, housenumber: "2/A", siteid: 111400010003, isfull: true, doServe: true},
    {streetid: 11140001, housenumber: "3", siteid: 111400010002, isfull: true, doServe: true},
    {streetid: 90878, housenumber: "1", siteid: 206000030001, isfull: true, doServe: true},
    {streetid: 90878, housenumber: "2", siteid: 206000030001, isfull: false, doServe: true},
    {streetid: 90874, housenumber: "21", siteid: 786432, isfull: false, doServe: false},
    {streetid: 90874, housenumber: "25", siteid: 786432, isfull: false, doServe: false},
    {streetid: 90874, housenumber: "27", siteid: 786432, isfull: false, doServe: false}
];

flats: Array<FlatRecord> = [
    {siteid: 786432, doorInfo: "Földszint 15", doServe: false},
    {siteid: 786432, doorInfo: "1. emelet 16", doServe: false},
    {siteid: 786432, doorInfo: "1. emelet 17", doServe: false},
    {siteid: 786432, doorInfo: "1. emelet 18", doServe: false},
    {siteid: 786432, doorInfo: "2. emelet 19", doServe: false},
    {siteid: 786432, doorInfo: "2. emelet 20", doServe: false},
    {siteid: 786432, doorInfo: "2. emelet 21", doServe: true},
    {siteid: 786432, doorInfo: "3. emelet 22", doServe: false},
    {siteid: 786432, doorInfo: "3. emelet 23", doServe: false},
    {siteid: 786432, doorInfo: "3. emelet 24", doServe: false},
    {siteid: 786432, doorInfo: "4. emelet 25", doServe: false},
    {siteid: 786432, doorInfo: "4. emelet 26", doServe: false},
    {siteid: 786432, doorInfo: "4. emelet 27", doServe: false}
];

    getZips() : Promise<Array<ZipRecord>> {
        return new Promise((resolve, reject) => {
            setTimeout( () => {
                resolve(this.zips);
            }, this.delaySimulation);
        });
    }

    getCitiesbyZip(zip: string) : Promise<Array<CityRecord>> {
        let returncities: Array<CityRecord> = [];
        return new Promise((resolve, reject) => {
            setTimeout( () => {
                for (let i=0; i<this.cities.length; i++) {
                    if(this.cities[i].zip == zip)
                        returncities.push(this.cities[i]);
                }
                resolve(returncities);
            }, this.delaySimulation);
        });
    }

    getStreetsbyCity(postcodeCityID: number) : Promise<Array<StreetRecord>> {
        let returnstreets = [];
        return new Promise((resolve, reject) => {
            setTimeout( () => {
                for (let i=0; i<this.streets.length; i++) {
                    if(this.streets[i].postcodeCityID == postcodeCityID)
                        returnstreets.push(this.streets[i])
                }
                resolve(returnstreets);
            }, this.delaySimulation);
        });
    }

    getHousesbyStreet(streetid: number) : Promise<Array<HouseRecord>> {
        let returnhouses = [];
        return new Promise((resolve, reject) => {
            setTimeout( () => {
                for (let i=0; i<this.houses.length; i++) {
                    if(this.houses[i].streetid == streetid)
                        returnhouses.push(this.houses[i])
                }
                resolve(returnhouses);
            }, this.delaySimulation);
        });
    }

    getFlatsbyHouse(siteid: number) : Promise<Array<FlatRecord>> {
        let returnflats = [];
        return new Promise((resolve, reject) => {
            setTimeout( () => {
                for (let i=0; i<this.flats.length; i++) {
                    if(this.flats[i].siteid == siteid)
                        returnflats.push(this.flats[i])
                }
                resolve(returnflats);
            }, this.delaySimulation);
        });
    }

}